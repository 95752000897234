import React from 'react';
import './copyright.css';

const Copyright = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-info">
          {/* <p className="company-name">
            &copy; {currentYear} Dentist India Plus Technology Services
          </p>
          <p className="parent-company">
            A subsidiary of First Health and Technology Services
          </p> */}
          <span className='company-name'>
          Parent Enterprise : First Health and Technology Services <br/>
          Subsidiary Unit : Dentist India Plus Technology Services
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Copyright;
