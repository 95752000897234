import "./App.css";
import Header from "./views/header/Header";
import Content from "./views/content/Content";
import Footer from "./views/footer/Footer";
import Scroll from "./components/scroll/Scroll";
import Copyright from "./views/copyright/copyright";
function App() {
  return (
    <div className="App">
      <Header />
      <Content />
      <Footer />
      <Copyright/>
    </div>
  );
}

export default App;
